.nav-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  padding: 20px 0;
  align-items: center;
}

.nav-item {
  margin-left: 20px;
}

.nav-link {
  text-decoration: none;
  color: black;
  font-weight: bold;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #f35571;
}

.nav-button {
  background-color: #f35571;
  border: none;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
}

/* تصميم زر الهامبورجر */
.hamburger-menu {
  display: none;
  cursor: pointer;
}

.hamburger-menu-icon {
  width: 30px;
  height: 4px;
  background-color: black;
  margin: 6px 0;
  transition: background-color 0.3s ease;
}

.hamburger-menu.open .hamburger-menu-icon:nth-child(1) {
  transform: rotate(-45deg) translate(-9px, 6px);
}

.hamburger-menu.open .hamburger-menu-icon:nth-child(2) {
  opacity: 0;
}

.hamburger-menu.open .hamburger-menu-icon:nth-child(3) {
  transform: rotate(45deg) translate(-8px, -7px);
}

/* تصميم الهامبورجر عند الفتح */
.hamburger-menu.open .hamburger-menu-icon {
  background-color: transparent;
}

.hamburger-menu.open .hamburger-menu-icon:nth-child(1) {
  transform: rotate(45deg) translate(-5px, 5px);
}

.hamburger-menu.open .hamburger-menu-icon:nth-child(3) {
  transform: rotate(-45deg) translate(-5px, -5px);
}

/* تصميم القائمة للأجهزة الصغيرة */
@media screen and (max-width: 768px) {
  .nav-list {
    flex-direction: column;
    position: absolute;
    top: 60px; /* ارتفاع الهامبورجر عند النقر */
    right: 0;
    background-color: white;
    width: 100%;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    transform: translateY(-300%);
    z-index: 1000;
  }

  .nav-list.open {
    transform: translateY(0);
  }

  .nav-item {
    margin-left: 0;
    margin-bottom: 10px;
  }

  .nav-button {
    width: 100%;
  }

  .hamburger-menu {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1500;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.books-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
  padding: 20px;
}

.book-card {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 20px;
  border:3px solid transparent;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.book-card:hover {
  transform: translateY(-5px);
}

.book-card h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.book-card p {
  font-size: 1rem;
  color: #666;
  margin-bottom: 20px;
}

.view-button {
  background-color: #f35571;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.book-card.active{
  border:3px solid #f35571;
}

/* .view-button:hover {
  background-color: #0056b3;
} */


.book-card h2, .book-card p, .book-card{
  text-align:  center !important;
}


.tooltip-content {
  padding: 10px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.tooltip-content .rs-input {
  margin-bottom: 10px;
}

.tooltip-content .send-button {
  width: 100%;
}

.rs-tooltip, .rs-tooltip[class*=placement-top]:after{
  background-color: transparent !important;
  border-color:  transparent !important;
}


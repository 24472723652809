@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Playball&family=Protest+Revolution&family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
input::placeholder,
input,
label,
span {
  margin: 0;
  padding: 0;
  border: 0;
  /* font: inherit; */
  font-size: 100%;
  vertical-align: baseline;
  direction: rtl !important;
  text-align: right !important;
  font-weight: 700 !important;
}
* {
  font-family: "Tajawal", sans-serif !important;
}
html {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption,
th,
td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

q,
blockquote {
  quotes: none;
}
q:before,
q:after,
blockquote:before,
blockquote:after {
  content: "";
  content: none;
}

a img {
  border: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

/* Colors */
/* ---------------------------------------- */
* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  text-align: center;
  font-family: 'Lato', 'sans-serif';
  font-weight: 400;
}

a {
  text-decoration: none;
}

header,
form {
  padding: 4em 10%;
}

header {
  background-color: #f35571;
  color: white;
}
header h1 {
  font-weight: 400;
  text-transform: uppercase;
  font-size: 32px;
  margin-bottom: 10px;
}
header h1 i {
  color: #055a8b;
}
header h1 span {
  font-weight: 300;
}
header p {
  font-weight: 400;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.9);
  letter-spacing: 0.05em;
  margin-bottom: 10px;
}
header h3,
header h3 a {
  color: #32ccfe;
}
header h3 a,
header h3 a a {
  -moz-transition: color 0.3s;
  -o-transition: color 0.3s;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
header h3 a:hover,
header h3 a a:hover {
  color: white;
}

h2.heading {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 300;
  text-align: left;
  color: #506982;
  border-bottom: 1px solid #506982;
  padding-bottom: 3px;
  margin-bottom: 20px;
}

.controls {
  text-align: left;
  position: relative;
}
.controls input[type="text"],
.controls input[type="email"],
.controls input[type="number"],
.controls textarea,
.controls button,
.controls select {
  padding: 12px;
  font-size: 14px;
  border: 1px solid #c6c6c6;
  width: 100%;
  margin-bottom: 18px;
  color: #888;
  font-family: 'Lato', 'sans-serif';
  font-size: 16px;
  font-weight: 300;
  background-color: #fff;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.controls input[type="text"]:focus,
.controls input[type="text"]:hover,
.controls input[type="number"]:focus,
.controls input[type="number"]:hover,
.controls input[type="email"]:focus,
.controls input[type="email"]:hover,
.controls input[type="tel"]:focus,
.controls input[type="tel"]:hover,
.controls textarea:focus,
.controls textarea:hover,
.controls button:focus,
.controls button:hover,
.controls select:focus,
.controls select:hover {
  outline: none;
  border-color: #9fb1c1;
}
.controls input[type="text"]:focus + label,
.controls input[type="text"]:hover + label,
.controls input[type="number"]:focus + label,
.controls input[type="number"]:hover + label,
.controls input[type="email"]:focus + label,
.controls input[type="email"]:hover + label,
.controls input[type="tel"]:focus + label,
.controls input[type="tel"]:hover + label,
.controls textarea:focus + label,
.controls textarea:hover + label,
.controls button:focus + label,
.controls button:hover + label,
.controls select:focus + label,
.controls select:hover + label {
  color: #077abc;
  cursor: text;
}
.controls .fa-sort {
  position: absolute;
  right: 10px;
  top: 17px;
  color: #999;
}
.controls select {
  -moz-appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
}
.controls label {
  position: absolute;
  right: 8px;
  top: 12px;
  color: #999;
  font-size: 16px;
  display: inline-block;
  padding: 4px 10px;
  font-weight: 400;
  background-color: rgba(255, 255, 255, 0);
  pointer-events: none;
  -moz-transition:
    color 0.3s,
    top 0.3s,
    background-color 0.8s;
  -o-transition:
    color 0.3s,
    top 0.3s,
    background-color 0.8s;
  -webkit-transition:
    color 0.3s,
    top 0.3s,
    background-color 0.8s;
  transition:
    color 0.3s,
    top 0.3s,
    background-color 0.8s;
}
.controls label.active {
  top: -18px;
  color: #555;
  background-color: white;
}
.controls textarea {
  resize: none;
  height: 200px;
}
.controls button {
  cursor: pointer;
  background-color: #07b3a1;
  border: none;
  color: #fff;
  padding: 12px 0;
}
.controls button:hover {
  background-color: #08ccb7;
}

.form-row {
  display: flex;
  justify-content: space-between; /* Adjust this property as needed */
}

.form-group {
  flex: 1; /* Make the form-group take up equal space within the form-row */
  margin-bottom: 6px;
  margin-right: 10px; /* Optional: Adjust margin between form-groups */
}

.radio-group {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  gap: 8px;
}
.radio_con {
  display: flex;
  align-items: center;
}
input[type='radio'] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #f35571;
  margin-right: 10px;
}

input[type='radio']:checked {
  background-color: #f35571;
}

label {
  cursor: pointer;
}

.radio-group label {
  margin-right: 10px;
  color: #555;
}

.radio-group input[type='radio'] + label {
  /* margin-bottom: 5px; */
  margin-right: 10px;
}

.radio-group input[type='radio'] + label:hover,
input[type='radio']:checked + label {
  color: #f35571;
}

@media (max-width: 792px) {
  .form-row {
    flex-direction: column;
  }
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}
